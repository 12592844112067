<template>
  <nav class="c-paginator">
    <a
      class="c-paginator__link rounded-l-bts-sm px-8"
      @click="onClickPageLink({ page: page - 1 })"
    >
      <FontAwesomeIcon
        :icon="['fak', 'chevron-left']"
        :style="{ height: '12px', width: '12px' }"
        class="c-paginator__link__icon"
      />
    </a>

    <a
      v-for="(pageInfo, index) in pageSequence"
      :key="'page_number_' + index"
      :class="{
        'c-paginator__link--active': pageInfo.page === page,
      }"
      class="c-paginator__link"
      @click="onClickPageLink(pageInfo)"
    >
      <div
        v-if="pageInfo.page !== SEQUENCE_DIVIDER"
        :class="{
          'c-paginator__link-text--active': pageInfo.page === page,
        }"
        class="px-6 py-2"
      >
        {{ pageInfo.page }}
      </div>
      <div v-else class="px-4">
        <FontAwesomeIcon
          :icon="['fak', 'menu']"
          :style="{ height: '12px', width: '12px' }"
          class="c-paginator__link__icon"
        />
      </div>
    </a>

    <a
      class="c-paginator__link c-paginator__link-right px-8"
      @click="onClickPageLink({ page: page + 1 })"
    >
      <FontAwesomeIcon
        :icon="['fak', 'chevron-right']"
        :style="{ height: '12px', width: '12px' }"
        class="c-paginator__link__icon"
      />
    </a>
  </nav>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

interface Props {
  lastPage?: number;
  page?: number;
}
const props = withDefaults(defineProps<Props>(), {
  lastPage: 1,
  page: 1,
});
const emit = defineEmits(['pageClick']);
const BACKWARD = 'backward';
const FORWARD = 'forward';
const MIN_PAGE = 1;
const SEQUENCE_DIVIDER = '...';
const SEQUENCE_LENGTH = 5;

const currentSequenceSegment = computed(() =>
  Math.ceil(props.page / SEQUENCE_LENGTH),
);
const sequenceLength = computed(() => SEQUENCE_LENGTH - 1);
const pageSequence = computed(() => {
  const sequence = [];
  let sequenceStart = 1;
  for (let i = 1; i < currentSequenceSegment.value; i++) {
    sequenceStart += SEQUENCE_LENGTH;
  }
  const sequenceEnd =
    props.lastPage >= sequenceStart + sequenceLength.value
      ? sequenceStart + sequenceLength.value
      : props.lastPage;

  for (let i = sequenceStart; i <= sequenceEnd; i++) {
    sequence.push({
      page: i,
    });
  }
  if (!sequence.find((pageInfo) => pageInfo.page === props.lastPage)) {
    sequence.push({
      page: SEQUENCE_DIVIDER,
      direction: FORWARD,
    });
    sequence.push({
      page: props.lastPage,
    });
  }
  if (!sequence.find((pageInfo) => pageInfo.page === MIN_PAGE)) {
    sequence.unshift({
      page: SEQUENCE_DIVIDER,
      direction: BACKWARD,
    });
    sequence.unshift({
      page: MIN_PAGE,
    });
  }
  return sequence;
});

function getSequenceSegmentRoute(segmentNumber: number) {
  let page = 1;

  for (let i = 1; i < segmentNumber; i++) {
    page += SEQUENCE_LENGTH;
  }

  emit('pageClick', page);
}
function onClickPageLink(pageInfo: {
  page: number | string;
  direction?: string;
}) {
  if (pageInfo.page === SEQUENCE_DIVIDER) {
    const nextSegment =
      pageInfo.direction === BACKWARD
        ? currentSequenceSegment.value - 1
        : currentSequenceSegment.value + 1;

    return getSequenceSegmentRoute(nextSegment);
  } else if (typeof pageInfo.page === 'number') {
    let page = pageInfo.page;

    if (page < MIN_PAGE) {
      page = MIN_PAGE;
    } else if (page > props.lastPage) {
      page = props.lastPage;
    }

    emit('pageClick', page);
  }
}
</script>
<style lang="pcss" scoped>
.c-paginator {
  @apply flex flex-row;
}
.c-paginator__link__icon {
  @apply text-neutral-accent;
}

.c-paginator__link {
  @apply text-sm border border-solid border-neutral-border py-14 border-r-white px-4
  text-neutral-text-weak flex flex-row justify-center items-center font-bold bg-white cursor-pointer;
  height: 24px;
  &:hover:not(:focus-visible):not(.c-paginator__link--active) {
    @apply text-secondary-text border-secondary-background-medium;
  }
  &:hover:not(:focus-visible):not(.c-paginator__link--active)
    + .c-paginator__link {
    @apply border-l-white;
  }
  &:focus-visible {
    @apply border-secondary-background-medium outline-none;
  }
  &:focus-visible + .c-paginator__link {
    @apply border-l-white;
  }
  &:hover:not(:focus-visible) .c-paginator__link__icon {
    @apply text-secondary-text;
  }
}
.c-paginator__link-right {
  @apply rounded-r-bts-sm border-r-neutral-border hover:border-r-secondary-accent;
}
.c-paginator__link-text--active {
  @apply text-secondary-text bg-secondary-background-weak rounded-bts-xs;
}
</style>
